import React from 'react';
import './NotFound.css';

export default function NotFound() {
  return(
    <div className='NotFound'>
      <h3>
        Page not found!
      </h3>
    </div>
  )
}